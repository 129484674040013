import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

interface IButtonExtraProps {
  padding?: number;
}

export const Basicbutton = styled(<any>Button)<ButtonProps & IButtonExtraProps>`
  min-height: 48px;
  border-radius: 4px;
  padding: 0 ${props => props.padding || 30}px;
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const BlueButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  border: none;
  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.white};
  }
  &:active {
    background-color: ${props => props.theme.colors.superDarkBlue};
    color: ${props => props.theme.colors.white};
  }
  &:focus {
    background-color: ${props => props.theme.colors.superDarkBlue};
    color: ${props => props.theme.colors.white};
  }
`;

export const GreyButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.darkGrey};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.grey};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superlightGrey};
    color: ${props => props.theme.colors.white};
  }
  &:active {
    background-color: ${props => props.theme.colors.superDarkGrey};
    color: ${props => props.theme.colors.white};
  }
  &:focus {
    background-color: ${props => props.theme.colors.superDarkGrey};
    color: ${props => props.theme.colors.white};
  }
`;

export const RedButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.darkRed};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.lightRed};
    color: ${props => props.theme.colors.white};
  }
  &:active {
    background-color: ${props => props.theme.colors.superDarkRed};
    color: ${props => props.theme.colors.white};
  }
  &:focus {
    background-color: ${props => props.theme.colors.superDarkRed};
    color: ${props => props.theme.colors.white};
  }
`;

export const WhiteBlueButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.blue};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superLightBlue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:active {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superDarkBlue};
    border: 1px solid ${props => props.theme.colors.superDarkBlue};
  }
  &:focus {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superDarkBlue};
    border: 1px solid ${props => props.theme.colors.superDarkBlue};
  }
`;

export const WhiteRedButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  border: 1px solid ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.red};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.red};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:active {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superDarkRed};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:focus {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superDarkRed};
    border: 1px solid ${props => props.theme.colors.white};
  }
`;

export const EmergencySelect = styled(Basicbutton)`
  min-height: 68px;
  padding: 0 35px;
  border: 1px solid ${props => props.theme.colors.superDarkBlue};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
  color: ${props => props.theme.colors.superDarkBlue};
  font-size: ${props => props.theme.sizes.L};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superlightGrey};
  }
  &:active {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.superDarkBlue};
  }
  &:focus {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.superDarkBlue};
  }
`;
export const ResetButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.superLightBlue};
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.superLightBlue};
  &:hover {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.lightBlue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:active {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:focus {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
`;

export const ResetBlueWhiteButton = styled(WhiteBlueButton)`
  border: 1px solid ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:active {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:focus {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
`;
