import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import {
  AppState,
  Filter,
  setFilter,
  SetFilterActionCreator,
  GroupFilter,
  selectPlayerGroupsOptions,
} from '../../../redux/modules';
import { Box, Select, Text } from '../../../components';
import { IItemSelect } from '../../../components/Select';
import { Body } from './styles';

interface IProps {
  setFilter: SetFilterActionCreator;
  groupList: IItemSelect[];
  intl?: InjectedIntl;
}

class FilterBox extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onHandleFilter = this.onHandleFilter.bind(this);
  }

  onHandleFilter(value: GroupFilter) {
    this.props.setFilter(Filter.GROUP, value);
  }

  render() {
    return (
      <Box
        defaultTitleWrapper={this.props.intl!.formatMessage({
          id: 'creativeList.filterCreative',
        })}
      >
        <Body>
          <Text bold={true}>
            <FormattedMessage id="dashboard.bygroup" />
          </Text>
          <Select
            items={this.props.groupList}
            defaultValue={this.props.intl!.formatMessage({
              id: 'dashboard.allGroups',
            })}
            onPress={this.onHandleFilter}
            placeholder={this.props.intl!.formatMessage({
              id: 'dashboard.allGroups',
            })}
          />
        </Body>
      </Box>
    );
  }
}

export default connect(
  (state: AppState) => ({
    groupList: selectPlayerGroupsOptions(state),
  }),
  {
    setFilter,
  }
)(injectIntl<any>(FilterBox));
