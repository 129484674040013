// @flow
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { getTemplates } from '../../../redux/modules/templates/action';
import {
  addCreativityFromTemplate,
  AddCreativityFromTemplateActionCreator,
} from '../../../redux/modules/creativities';
import { selectTemplateList } from '../../../redux/modules/templates/selector';
import { GetTemplatesActionCreator } from '../../../redux/modules/templates/types';
import { Box, Button, Select, Text } from '../../../components';
import { Body, Filter } from './styles';
import { AppState } from '../../../redux/modules';

interface IState {
  template?: {
    _id: string;
    name: string;
  };
}

interface IProps {
  getTemplates?: () => GetTemplatesActionCreator;
  addCreativityFromTemplate?: AddCreativityFromTemplateActionCreator;
  templateList: any;
  intl?: InjectedIntl;
}

class TemplateList extends React.Component<IProps, IState> {
  state: IState = {};

  componentDidMount() {
    this.props.getTemplates!();
  }

  onCreateTemplate = () => {
    const { template } = this.state;

    return template ? this.props.addCreativityFromTemplate!(template) : false;
  };

  onChangeSelection = (_id: string, option: any) => {
    this.setState({
      template: {
        _id: option.props.value,
        name: option.props.children,
      },
    });
  };

  render() {
    const { templateList } = this.props;

    const favouritesList = templateList.filter(
      (temp: { favourite: boolean }) => temp.favourite
    );
    const regularItems = templateList.filter(
      (temp: { favourite: boolean }) => !temp.favourite
    );

    return (
      <Box
        typeHeader={'blue'}
        typeTitle={'white'}
        typeBody={'bluealert'}
        defaultTitleWrapper={this.props.intl!.formatMessage({
          id: 'creativeList.createCreative',
        })}
      >
        <Body>
          <Text bold={true}>
            <FormattedMessage id="creativeList.selectTemplate" />
          </Text>
          <Filter>
            <Select
              defaultActiveFirstOption={false}
              favouriteItems={favouritesList}
              items={regularItems}
              defaultValue={this.props.intl!.formatMessage({
                id: 'select.defaultValue.template',
              })}
              onPress={this.onChangeSelection}
              placeholder={this.props.intl!.formatMessage({
                id: 'select.defaultValue.template',
              })}
              value={
                (this.state.template && this.state.template.name) ||
                this.props.intl!.formatMessage({
                  id: 'select.defaultValue.template',
                })
              }
            />
            <Button
              type="blue"
              onClick={this.onCreateTemplate}
              button={this.props.intl!.formatMessage({
                id: 'creativeList.create',
              })}
            />
          </Filter>
        </Body>
      </Box>
    );
  }
}

export default connect(
  (state: AppState) => ({
    templateList: selectTemplateList(state),
  }),
  {
    addCreativityFromTemplate,
    getTemplates,
  }
)(injectIntl<any>(TemplateList));
