import React, { createRef } from 'react';
import moment, { Moment } from 'moment';
import { injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Picker, PickerComponent } from '../../../../components';
import WeekDaySelector from '../WeekDaySelector';

import {
  IVisibility,
  updateVisibilityDateOrTime,
  AppState,
  Status,
} from '../../../../redux/modules';

import { Section, SectionPicker, H1Custom } from '../styles';

interface IProps {
  creativityId: string;
  intl?: InjectedIntl;
  updateVisibilityDateOrTime?: any;
  visibility?: IVisibility;
  status: Status;
}

class ScheduleSelector extends React.Component<IProps> {
  private dateFrom: React.RefObject<PickerComponent> = createRef();

  private dateTo: React.RefObject<PickerComponent> = createRef();

  changeVisibility = (group: string, type: string) => (date: Moment) => {
    if (group === 'time') {
      const hour = date ? date.hour() : undefined;
      const minutes = date ? date.minute() : undefined;
      const value =
        hour !== undefined && minutes !== undefined
          ? `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`
          : undefined;
      this.props.updateVisibilityDateOrTime(
        this.props.creativityId,
        group,
        type,
        value
      );
    }

    if (group === 'date') {
      const value = date ? date.toDate().toLocaleDateString() : undefined;
      this.props.updateVisibilityDateOrTime(
        this.props.creativityId,
        group,
        type,
        value
      );
    }
  };

  getDateVisibility(group: string, type: string) {
    const { visibility } = this.props;
    let date =
      visibility &&
      visibility.rules &&
      visibility.rules.find(
        x =>
          x.type === group &&
          x.comparator === (type === 'dateFrom' ? 'gte' : 'lte')
      );
    return date ? moment(date.value, 'DD-MM-YYYY') : undefined;
  }

  getTimeVisibility(group: string, type: string, defaultTime: string) {
    const { visibility } = this.props;
    let time =
      visibility &&
      visibility.rules &&
      visibility.rules.find(
        x =>
          x.type === group &&
          x.comparator === (type === 'timeFrom' ? 'gte' : 'lte')
      );
    return time
      ? moment(time.value, 'HH:mm:ss')
      : moment(defaultTime, 'HH:mm:ss');
  }

  disabledDateFrom = (current: Moment) => {
    // Can not select days before today
    const { visibility } = this.props;
    let valueToCompare =
      visibility &&
      visibility.rules &&
      visibility.rules.find(x => x.type === 'date' && x.comparator === 'lte');
    return (
      (valueToCompare &&
        current >
          moment(valueToCompare.value, 'DD-MM-YYYY')
            .add(-1, 'days')
            .startOf('day')) ||
      current < moment().subtract(1, 'day')
    );
  };

  disabledDateTo = (current: Moment) => {
    // Can select today
    const { visibility } = this.props;
    const valueToCompare =
      visibility &&
      visibility.rules &&
      visibility.rules.find(x => x.type === 'date' && x.comparator === 'gte');
    return (
      !valueToCompare ||
      (valueToCompare &&
        valueToCompare.value &&
        current &&
        current < moment(valueToCompare.value, 'DD-MM-YYYY').startOf('day'))
    );
  };

  render() {
    const { creativityId, status, intl } = this.props;

    return (
      <Section>
        <Row>
          <H1Custom>
            {intl!.formatMessage({
              id: 'editor.info.screen.titleActivity',
            })}
          </H1Custom>
        </Row>
        <Row>
          <Col span={12}>
            <SectionPicker>
              <span>
                {intl!.formatMessage({
                  id: 'editor.visibility.from',
                })}
              </span>
              <Picker
                id={`${creativityId}_dateFrom`}
                ref={this.dateFrom}
                disabled={
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                disabledDate={this.disabledDateFrom}
                value={this.getDateVisibility('date', 'dateFrom')}
                onPress={this.changeVisibility('date', 'gte')}
                type={'date'}
              />
            </SectionPicker>
          </Col>
          <Col span={12}>
            <SectionPicker>
              <span>
                {intl!.formatMessage({
                  id: 'editor.visibility.to',
                })}
              </span>
              <Picker
                id={`${creativityId}_dateTo`}
                ref={this.dateTo}
                disabled={
                  status == Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                disabledDate={this.disabledDateTo}
                value={this.getDateVisibility('date', 'dateTo')}
                onPress={this.changeVisibility('date', 'lte')}
                type={'date'}
              />
            </SectionPicker>
          </Col>
        </Row>
        <WeekDaySelector creativityId={creativityId} status={status} />
        <Row>
          <Col span={12}>
            <SectionPicker>
              <span>
                {intl!.formatMessage({
                  id: 'editor.visibility.between',
                })}
              </span>
              <Picker
                id={`${creativityId}_timeFrom`}
                disabled={
                  status == Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                value={this.getTimeVisibility('time', 'timeFrom', '00:00:00')}
                onPress={this.changeVisibility('time', 'gte')}
                type={'time'}
              />
            </SectionPicker>
          </Col>
          <Col span={12}>
            <SectionPicker>
              <span>
                {intl!.formatMessage({
                  id: 'editor.visibility.and',
                })}
              </span>
              <Picker
                id={`${creativityId}_timeTo`}
                disabled={
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                value={this.getTimeVisibility('time', 'timeTo', '23:59:59')}
                onPress={this.changeVisibility('time', 'lte')}
                type={'time'}
              />
            </SectionPicker>
          </Col>
        </Row>
      </Section>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({}),
  { updateVisibilityDateOrTime }
)(injectIntl<any>(ScheduleSelector));
