import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';

export interface IUser {
  updatedAt: string;
  name: string;
  nickname: string;
  email: string;
  picture: string;
  userMetadata?: {
    name?: string;
    surname?: string;
  };
  appMetadata?: {
    role: string;
  };
}

export enum Roles {
  ADMIN = 'admin',
}

export interface ILoginResponse {
  idToken: string;
  accessToken: string;
}

export interface IToken {
  exp: number;
  aud: string;
  name: string;
  nickname: string;
  email: string;
  picture: string;
  updated_at: string;
  [key: string]: any;
}

export interface VerifyTokenResponse {
  error?: string;
  user?: IUser;
}

type ThunkResult<R> = ThunkAction<R, AppState, void, UserAction>;

export type LoginActionCreator = (
  data: { username: string; password: string }
) => ThunkResult<void>;

export type LoginRequestAction = {
  type: 'USER/LOGIN_REQUEST';
};

export type LoginSuccessAction = {
  type: 'USER/LOGIN_SUCCESS';
  data: ILoginResponse;
};

export type LoginFailureAction = {
  type: 'USER/LOGIN_FAILURE';
  error: any;
};

export type VerifyFailureAction = {
  type: 'USER/VERIFY_FAILURE';
  error: any;
};

export type VerifySuccessAction = {
  type: 'USER/VERIFY_SUCCESS';
  data: IUser;
};

export type LogoutAction = {
  type: 'USER/LOGOUT';
};

export type PasswordResetActionCreator = (
  data: { username: string; password?: string }
) => ThunkResult<void>;

export type PasswordChangeRequestAction = {
  type: 'USER/PASSWORD_CHANGE_REQUEST';
};

export type PasswordChangeSuccessAction = {
  type: 'USER/PASSWORD_CHANGE_SUCCESS';
  data: { message: string };
};

export type PasswordChangeFailureAction = {
  type: 'USER/PASSWORD_CHANGE_FAILURE';
  error: any;
};

export type UserAction =
  | LoginFailureAction
  | LoginRequestAction
  | LoginSuccessAction
  | VerifyFailureAction
  | VerifySuccessAction
  | PasswordChangeRequestAction
  | PasswordChangeSuccessAction
  | PasswordChangeFailureAction
  | LogoutAction;
