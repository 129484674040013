import { combineReducers } from 'redux';
import { intlReducer, IntlState } from 'react-intl-redux';
import { ILocaleState, locale } from './locale/reducer';
import { ICreativityState, creativities } from './creativities/reducer';
import { IFilterState, filters } from './filters/reducer';
import { ITemplateState, templates } from './templates/reducer';
import { IPlayerState, players } from './players/reducer';
import { IUserState, user } from './user/reducer';
import { RouterState } from 'connected-react-router';

export interface AppState {
  creativities: ICreativityState;
  filters: IFilterState;
  players: IPlayerState;
  templates: ITemplateState;
  user: IUserState;
  locale: ILocaleState;
  intl: IntlState;
  router: RouterState;
}

const rootReducer = combineReducers({
  creativities,
  filters,
  locale,
  players,
  templates,
  user,
  intl: <any>intlReducer,
});

export default rootReducer;

export * from './creativities';
export * from './filters';
export * from './locale';
export * from './players';
export * from './router';
export * from './templates';
export * from './user';
