import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0px;
  min-height: 85px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 250px;
`;
