import styled from 'styled-components';
import { Button } from '../../../../components';
import { Icon } from 'antd';

export const Container = styled.div`
  margin-top: 20px;
  justify-content: space-between;
`;

export const FlexContainer = styled.div`
  margin-top: 15px;
  margin-right: 18px;
  display: flex;
  justify-content: space-between;
`;

export const EmergencyButtonFlexContainer = styled.div`
  margin-top: 15px;
  margin-right: 18px;
  display: flex;
  justify-content: space-between;
  button {
    width: 100%;
  }
`;

export const WrapperContainer = styled.div`
  width: 100%;
`;

export const WrapperText = styled.div`
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: ${props => props.theme.sizes.L};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

export const ButtonWrapper = styled.div`
  margin-right: 18px;
  button {
    width: 100%;
    margin-right: 18px;
  }
`;

export const WrapperButton = styled.div`
  margin: 0 auto;
  width: 50%;
  display: flex;
  justify-content: center;
  button {
    margin: 5px;
  }
`;

export const UnpublishButtonWrapper = styled.div`
  margin-right: 18px;
  margin-top: 15px;
  button {
    width: 100%;
    margin-right: 18px;
  }
`;

export const DisableDeleteButton = styled(Button)`
  &.disabled {
    cursor: not-allowed;
    color: lightgrey;
  }
`;

export const DenyCommentButton = styled(Button)`
  color: ${props => props.theme.colors.darkRed};
  background-color: ${props => props.theme.colors.megaLightGrey};
  border: 1px solid ${props => props.theme.colors.grey};
  :hover {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkRed};
    border: 1px solid ${props => props.theme.colors.grey};
  }
`;

export const CustomIcon = styled(Icon)`
  &.disabled {
    cursor: not-allowed;
    color: lightgrey;
  }
  &.redHover:hover {
    color: red;
  }
`;
