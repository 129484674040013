import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { sizes } from '../../theme';
import {
  AppState,
  unpublishCreativityByWarning,
  UnpublishCreativityByWarningActionCreator,
  ICreativity,
  selectTitle,
  selectCustomTitle,
  getPublishedEmergency,
  isEmergencyRoute,
  logout,
} from '../../redux/modules';

import LinkStyle from '../Link';
import Text from '../Text';
import Weatherwarning from '../Weatherwarning';

import {
  HeaderTitle,
  ListItem,
  Subheader,
  Listheader,
  Img,
  ListDivider,
  Container,
} from './styles';
import { ConfigConsumer, UserConsumer } from '../../providers';

interface IProps {
  dashboard?: string;
  header?: string;
  publishedEmergency?: ICreativity;
  unpublishCreativityByWarning?: UnpublishCreativityByWarningActionCreator;
  customTitle?: string;
  title?: string;
  name?: string;
  intl?: InjectedIntl;
  isEmergency?: boolean;
  logout?: any;
}

class Header extends Component<IProps> {
  render() {
    return (
      <ConfigConsumer>
        {config => (
          <UserConsumer>
            {user => (
              <>
                <Container>
                  <Container>
                    <Link to={'/'}>
                      <Img src={config.logo} />
                    </Link>
                    {this.props.publishedEmergency ? (
                      <Weatherwarning
                        unpublishCreativityByWarning={
                          this.props.unpublishCreativityByWarning!
                        }
                        emergency={this.props.publishedEmergency}
                      />
                    ) : null}
                  </Container>
                  <Listheader>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/'}
                        exact
                        text={this.props.intl!.formatMessage({
                          id: 'route.dashboard',
                        })}
                      />
                    </ListItem>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/creative'}
                        exact
                        text={this.props.intl!.formatMessage({
                          id: 'route.creative',
                        })}
                      />
                    </ListItem>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/emergency'}
                        exact
                        text={this.props.intl!.formatMessage({
                          id: 'route.emergency',
                        })}
                        disabled={!user.isAdmin}
                      />
                    </ListItem>
                    <ListDivider type="vertical" />{' '}
                    <ListItem>
                      <Text
                        link
                        bold
                        fontSize={sizes.M}
                        onClick={this.props.logout!}
                      >
                        <FormattedMessage id="app.logout" />
                      </Text>
                    </ListItem>
                  </Listheader>
                </Container>
                <Subheader emergency={this.props.isEmergency}>
                  <HeaderTitle>
                    {this.props.customTitle ? (
                      this.props.customTitle
                    ) : (
                      <FormattedMessage id={this.props.title!} />
                    )}
                  </HeaderTitle>
                </Subheader>
              </>
            )}
          </UserConsumer>
        )}
      </ConfigConsumer>
    );
  }
}

export default connect(
  (state: AppState) => ({
    title: selectTitle(state),
    isEmergency: isEmergencyRoute(state),
    customTitle: selectCustomTitle(state),
    publishedEmergency: getPublishedEmergency(state),
  }),
  { logout, unpublishCreativityByWarning }
)(injectIntl<any>(Header));
