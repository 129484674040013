import styled from 'styled-components';
import { Col, Row } from 'antd';
import { RowProps, RowState } from 'antd/lib/grid/row';
import { ColProps } from 'antd/lib/grid/col';

export const Section = styled.div``;

export const ContainerSection = styled.div`
  margin: 0px 100px 10px 100px;
`;

export const ContainerBox = styled.div`
  padding-top: 30px;
`;

export const ColBox = styled(<any>Col)<ColProps | {}>``;

export const RowBox = styled(<any>Row)<RowProps | RowState>`
  margin-bottom: 30px;
`;

export const WhrapperBodyBox = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: auto;
  display: grid;
  justify-content: space-around;
  justify-items: center;
  grid-gap: 10px;
`;

export const BoxNoEmergency = styled.div`
  height: 110px;
  white-space: normal;
  text-align: center;
  padding: 10px;
  margin: 5px;
  color: ${props => props.theme.colors.darkBlue};
  background: transparent;
  font-weight: ${props => props.theme.fontWeights.semiBold};
  margin: auto;
`;

export const SpanBox = styled.span`
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const DivStyle = styled.div`
  button {
    width: 150px;
    height: 100px;
    word-break: break-all;
    white-space: normal;
    text-align: center;
    padding: 10px;
    margin: 5px;
    overflow-x: hidden;
    & > span {
      display: block;
    }
  }
`;
