import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Checkbox, CheckboxGroup } from '../../../../components';
import {
  selectVisibilityWeekday,
  updateVisibilityWeekdays,
  AppState,
  Status,
} from '../../../../redux/modules';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IProps {
  creativityId: string;
  checkedList?: number[];
  updateVisibilityWeekdays?: (id: string, checkedList: number[]) => void;
  intl?: InjectedIntl;
  status: Status;
}

const options = [
  { label: <FormattedMessage id="weekdayselector.monday" />, value: 2 },
  { label: <FormattedMessage id="weekdayselector.tuesday" />, value: 3 },
  { label: <FormattedMessage id="weekdayselector.wednesday" />, value: 4 },
  { label: <FormattedMessage id="weekdayselector.thursday" />, value: 5 },
  { label: <FormattedMessage id="weekdayselector.friday" />, value: 6 },
  { label: <FormattedMessage id="weekdayselector.saturday" />, value: 7 },
  { label: <FormattedMessage id="weekdayselector.sunday" />, value: 1 },
];
class WeekDaySelector extends PureComponent<IProps> {
  onChange = (checkedList: number[]) => {
    this.props.updateVisibilityWeekdays!(this.props.creativityId, checkedList);
  };

  onCheckAllChange = (e: CheckboxChangeEvent) => {
    this.props.updateVisibilityWeekdays!(this.props.creativityId, []);
  };

  areNoneSelected = () =>
    !this.props.checkedList || !this.props.checkedList.length;

  areAllSelected = () =>
    this.props.checkedList && this.props.checkedList.length === options.length;

  areSomeSelected = () => {
    return !this.areNoneSelected() && !this.areAllSelected();
  };

  render() {
    const { status, checkedList } = this.props;
    return (
      <Fragment>
        <Checkbox
          indeterminate={this.areSomeSelected()}
          disabled={
            status === Status.PUBLISHED ||
            this.areNoneSelected() ||
            status === Status.PENDING_VALIDATION
          }
          onChange={this.onCheckAllChange}
          checked={this.areNoneSelected()}
        >
          <FormattedMessage id="weekdayselector.all" />
        </Checkbox>
        <CheckboxGroup
          disabled={
            status === Status.PUBLISHED || status === Status.PENDING_VALIDATION
          }
          options={options}
          value={checkedList}
          onChange={this.onChange}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    checkedList: selectVisibilityWeekday(state, props.creativityId),
  }),
  { updateVisibilityWeekdays }
)(injectIntl<any>(WeekDaySelector));
