import { Languages } from '../../redux/modules/locale/types';

interface Instance {
  name: string;
  background: string;
  logo: string;
  color: string;
  language: Languages;
}

interface InstanceConfigs {
  [key: string]: Instance;
}

export const instanceConfigs: InstanceConfigs = {
  stockholm: {
    name: 'Stockholm',
    background: 'stockholm',
    logo: 'sverige',
    color: 'rgba(170, 186, 201, 0.7)',
    language: Languages.EN,
  },
  nice: {
    name: 'Nice',
    background: 'nice',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  caen: {
    name: 'Caen',
    background: 'caen',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  caenlamer: {
    name: 'Caen la Mer',
    background: 'caenlamer',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  ifs: {
    name: 'IFS',
    background: 'ifs',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  CCF: {
    name: 'CCF',
    background: 'caen',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  hsc: {
    name: 'Hérouville-Saint-Clair',
    background: 'caen',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  fso: {
    name: 'Fleury sur Orne',
    background: 'caen',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  siege: {
    name: 'Siege',
    background: 'siege',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  idfnord: {
    name: 'IDF Nord',
    background: 'idfnord',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  grandouest: {
    name: 'Grand Ouest',
    background: 'grandouest',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  grandest: {
    name: 'Grand Est',
    background: 'grandest',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  sudouest: {
    name: 'Sudouest',
    background: 'sudouest',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  mediterranee: {
    name: 'Mediterranee',
    background: 'mediterranee',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  raa: {
    name: 'RAA',
    background: 'nice',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
  default: {
    name: 'Nice',
    background: 'nice',
    logo: 'france',
    color: 'rgba(2, 56, 94, 0.7)',
    language: Languages.FR,
  },
};
