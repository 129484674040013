import * as React from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { verify, IUser } from '../../../redux/modules';
import { Header, Tab } from '../../../components';
import LoginPage from '../../Login';
import PasswordReset from '../../PasswordReset';
import Dashboard from '../../Dashboard';
import Editor from '../../Editor';
import Creative from '../../Creative';
import Emergency from '../../Emergency';
import { SectionCustom } from './styles';
import { AppState } from '../../../redux/modules';

const { Component, Fragment } = React;

interface IProps extends RouteComponentProps<{}> {
  verify?: any;
  firstLoadFinished?: any;
  user?: IUser;
}

class Routes extends Component<IProps> {
  componentDidMount() {
    this.props.verify();
  }

  render() {
    return (
      this.props.firstLoadFinished && (
        <Fragment>
          <SectionCustom>
            {!this.props.user ? (
              <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route path="/password-reset" exact component={PasswordReset} />
                <Redirect to="/login" />
              </Switch>
            ) : (
              <Fragment>
                <Header />
                <Switch>
                  <Route
                    path="/editor/:id/:emergency?"
                    exact
                    component={Editor}
                  />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/creative" exact component={Creative} />
                  <Route path="/emergency" exact component={Emergency} />
                  <Redirect to="/dashboard" />
                </Switch>
              </Fragment>
            )}
          </SectionCustom>
          <Tab />
        </Fragment>
      )
    );
  }
}

export default withRouter(
  connect(
    (state: AppState) => ({
      user: state.user.user,
      firstLoadFinished: state.user.isFinished,
    }),
    { verify }
  )(Routes)
);
