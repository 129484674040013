import React, { Component, Fragment } from 'react';
import { BasicLink, BlackLink } from './styles';

interface IProps {
  type?: string;
  text: string;
  to: string;
  exact?: boolean;
  disabled?: boolean;
}
class LinkStyle extends Component<IProps> {
  render() {
    const { type, text, to, ...rest } = this.props;
    if (type === 'header') {
      return (
        <Fragment>
          <BlackLink to={to} {...rest}>
            {text}
          </BlackLink>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <BasicLink to={to} {...rest}>
          {text}
        </BasicLink>
      </Fragment>
    );
  }
}

export default LinkStyle;
