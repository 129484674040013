import moment from 'moment';
import { createSelector } from 'reselect';
import { AppState } from '../';
import { ICreativity } from './types';
import { Filter, filtersSelector } from '../filters';
import { Status } from '../creativities';

export const selectCreativities = (emergency = false) => (state: AppState) => {
  const filteredSortedCreativities = state.creativities.all
    .filter(
      creativity =>
        emergency
          ? creativity.emergency
          : !creativity.emergency && creativity.status !== Status.CREATED
    )
    .sort(
      (creativeA, creativeB) =>
        new Date(creativeA.updatedAt) < new Date(creativeB.updatedAt) ? 1 : -1
    );
  return filteredSortedCreativities;
};

export const selectCreativitiesById = (
  state: AppState,
  creativityId: string
): ICreativity | null =>
  (creativityId &&
    state.creativities.all.find(
      creativity => creativity._id === creativityId
    )) ||
  null;

export const selectFilteredCreativities = (type: Filter) =>
  createSelector(
    selectCreativities(),
    filtersSelector(type),
    (items, filter) => {
      if (!filter) {
        return items;
      }

      return items.filter(filter);
    }
  );

export const selectCurrentCreativities = createSelector(
  selectFilteredCreativities(Filter.GROUP),
  creativities => {
    if (!creativities) {
      return [];
    }

    return creativities.filter(creative => {
      if (
        !creative.visibility ||
        !creative.visibility.state ||
        !creative.visibility.rules ||
        !creative.visibility.rules.length ||
        !creative.visibility.rules.find(x => x.comparator === 'lte') ||
        !creative.visibility.rules.find(x => x.comparator === 'gte')
      ) {
        return false;
      } else {
        return (
          isPublished(creative) &&
          hasStarted(creative) &&
          !hasFinished(creative)
        );
      }
    });
  }
);

export const selectNextCreativities = createSelector(
  selectFilteredCreativities(Filter.GROUP),
  creativities => {
    if (!creativities) {
      return [];
    }

    return creativities.filter(creative => {
      if (
        !creative.visibility ||
        !creative.visibility.state ||
        !creative.visibility.rules ||
        !creative.visibility.rules.length ||
        !creative.visibility.rules.find(x => x.comparator === 'lte') ||
        !creative.visibility.rules.find(x => x.comparator === 'gte')
      ) {
        return false;
      } else {
        return (
          isPublished(creative) &&
          !hasStarted(creative) &&
          !hasFinished(creative)
        );
      }
    });
  }
);

const isPublished = ({ publishedAt, status }: ICreativity) => {
  return publishedAt || status === Status.PUBLISHED;
};

const hasStarted = ({ visibility }: ICreativity) => {
  const startDate =
    visibility &&
    visibility.rules &&
    visibility.rules.find(x => x.comparator === 'gte' && x.type === 'date');
  const date = moment(startDate.value, 'D/M/YYYY');
  const today = moment().startOf('day');
  return date.isSameOrBefore(today);
};

const hasFinished = ({ visibility }: ICreativity) => {
  const endDate =
    visibility &&
    visibility.rules &&
    visibility.rules.find(x => x.comparator === 'lte' && x.type === 'date');
  const date = moment(endDate.value, 'D/M/YYYY');
  const today = moment().startOf('day');
  return today.isAfter(date);
};

export const getPublishedEmergency = (state: AppState) =>
  state.creativities.all.find(
    emergency => emergency.status === Status.PUBLISHED && !!emergency.emergency
  );

export const selectEmergenciesById = (state: AppState, emergencyId: string) =>
  emergencyId &&
  state.creativities.all.find(emergency => emergency._id === emergencyId);

export const hasVisibilitySelector = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  if (!creativity) {
    return false;
  }

  const { visibility } = creativity;

  if (!visibility) {
    return false;
  }

  return (
    visibility &&
    visibility.rules &&
    visibility.rules.find(
      rule => rule.type === 'date' && rule.comparator == 'gte'
    ) &&
    visibility.rules.find(
      rule => rule.type === 'date' && rule.comparator == 'lte'
    )
  );
};

export const hasLocationSelector = (state: AppState, creativityId: string) => {
  const creativity = selectCreativitiesById(state, creativityId);

  if (!creativity) {
    return false;
  }

  return (
    creativity.allPlayers ||
    (creativity.players && creativity.players.length) ||
    (creativity.groups && creativity.groups.length)
  );
};

export const hasAllPlayersSelected = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  return creativity && !!creativity.allPlayers;
};

export const selectNumberOfPlayersSelected = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  return (creativity && creativity.players && creativity.players.length) || 0;
};

export const selectNumberOfGroupsSelected = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  return (creativity && creativity.groups && creativity.groups.length) || 0;
};

export const hasVideosWithoutSourcesSelector = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  if (creativity) {
    const { slides } = creativity;

    for (let s = 0; s < slides.length; s++) {
      const { elements } = slides[s];

      for (let e = 0; e < elements.length; e++) {
        const element = elements[e];

        if (element.type === 'video' && !element.sources) {
          return true;
        }
      }
    }
  }

  return false;
};

export const selectVisibilityWeekday = (
  state: AppState,
  creativityId: string
) => {
  const creativity = selectCreativitiesById(state, creativityId);

  if (!creativity) {
    return false;
  }

  const { visibility } = creativity;

  if (!visibility) {
    return false;
  }

  const weekDayRule =
    visibility &&
    visibility.rules &&
    visibility.rules.find(rule => rule.type === 'weekDay');

  return weekDayRule && weekDayRule.value;
};
