import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, TextTag } from './styles';
import { Status } from '../../redux/modules/creativities/types';

interface IProps {
  status: Status;
}

export default class StatusTag extends PureComponent<IProps> {
  getStatus = (status: Status) => {
    switch (status) {
      case 2:
        return (
          <Tag className="draft-tag">
            <TextTag>
              <FormattedMessage id="tag.draft" />
            </TextTag>
          </Tag>
        );
      case 3:
        return (
          <Tag className="waiting-tag">
            <TextTag>
              <FormattedMessage id="tag.pending" />
            </TextTag>
          </Tag>
        );
      case 4:
        return (
          <Tag className="publish-tag">
            <TextTag>
              <FormattedMessage id="tag.publish" />
            </TextTag>
          </Tag>
        );
      case 1:
      default:
        return (
          <Tag className="created-tag">
            <TextTag>
              <FormattedMessage id="tag.created" />
            </TextTag>
          </Tag>
        );
    }
  };
  render() {
    return this.getStatus(this.props.status);
  }
}
