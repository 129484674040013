import React from 'react';
import { connect } from 'react-redux';
import { AppState, isAdmin } from '../../redux/modules';

interface IProps {
  isAdmin: boolean;
}

const defaultValues = {
  isAdmin: false,
};

const UserContext = React.createContext(defaultValues);

class UserProvider extends React.Component<IProps> {
  render() {
    const { isAdmin } = this.props;

    return (
      <UserContext.Provider
        value={{
          isAdmin,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;

export default connect((state: AppState) => ({
  isAdmin: isAdmin(state),
}))(UserProvider);
