import styled from 'styled-components';
import { Divider } from 'antd';

interface ISubheaderProps {
  emergency?: boolean;
}

export const Subheader = styled.section<ISubheaderProps>`
  background: ${props =>
    props.emergency ? props.theme.colors.red : props.theme.colors.blue};
`;

export const Container = styled.div`
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
`;

export const ContainerGrey = styled(Container)`
  background-color: ${props => props.theme.colors.lightGrey};
`;

export const LinkHeader = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.superDarkGrey};
`;
export const Img = styled.img<any>`
  height: 28px;
  width: auto;
  margin: 18px 0;
`;
export const Listheader = styled.div`
  display: flex;
  list-style: none;
  justify-content: flex-end;
  height: 64px;
  align-items: center;
  margin-bottom: 0;
`;

export const ListItem = styled.span`
  text-decoration: none;
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.M};
  padding: 24px;
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const ListDivider = styled(Divider)`
  color: ${props => props.theme.colors.DarkGrey};
`;

export const HeaderTitle = styled.h1`
  color: ${props => props.theme.colors.white};
  width: 100%;
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 30px;
  padding: 30px 0 25px 130px;
  margin: 0px;
`;
