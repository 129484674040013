import styled from 'styled-components';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export const Container = styled.div``;

export const Label = styled.label`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.S};
  font-weight: ${props => props.theme.fontWeights.medium};
  display: block;
  margin-bottom: 5px;
`;

export const CustomInput = styled(<any>Input)<InputProps>`
  width: 100%;
  display: block;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.sizes.S};
  font-weight: ${props => props.theme.fontWeights.light};
  background-color: ${props => props.theme.colors.megaLightGrey};
  padding: 20px 10px;
  margin-bottom: 20px;
`;
