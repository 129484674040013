export type SetLanguageAction = {
  type:
    | 'LOCALE/SPANISH'
    | 'LOCALE/FRENCH'
    | 'LOCALE/ENGLISH'
    | 'LOCALE/DEUTSCH';
};

export enum Languages {
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  EN = 'en',
}

export type SetLanguageActionCreator = (lang: Languages) => SetLanguageAction;
