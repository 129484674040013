import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
const activeClassName = 'active';

const DefaultLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  pointer-events: ${props => (props.isActive ? 'none' : 'auto')};
  &:hover {
    text-decoration: none;
  }
  &.${activeClassName} {
    text-decoration: none;
  }
`;

export const BasicLink = styled(DefaultLink)`
  color: ${props => props.theme.colors.Blue};
  &:hover {
    color: ${props => props.theme.colors.DarkBlue};
  }
  &.${activeClassName} {
    color: ${props => props.theme.colors.SuperDarkBlue};
  }
`;

export const BlackLink = styled(DefaultLink)`
  color: ${props => props.theme.colors.superDarkGrey};
  &:hover {
    color: ${props => props.theme.colors.darkGrey};
  }
  &.${activeClassName} {
    color: ${props => props.theme.colors.megaDarkGrey};
  }
`;
