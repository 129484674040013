import english from '../../../intl/en';
import spanish from '../../../intl/es';
import deutsch from '../../../intl/de';
import french from '../../../intl/fr';
import { Languages, SetLanguageAction } from './types';
import { instanceConfigs } from '../../../utils';

const { REACT_APP_INSTANCE } = process.env;

const config =
  instanceConfigs[REACT_APP_INSTANCE as string] || instanceConfigs.default;

const setIntl = (lang = Languages.FR) => {
  switch (lang) {
    case Languages.ES:
      return spanish;
    case Languages.EN:
      return english;
    case Languages.DE:
      return deutsch;
    case Languages.FR:
      return french;
    default:
      return french;
  }
};

export interface ILocaleState {
  lang: Languages;
  messages: any;
}

export const initialState: ILocaleState = {
  lang: config.language,
  messages: setIntl(config.language),
};

export const locale = (state = initialState, action: SetLanguageAction) => {
  switch (action.type) {
    case 'LOCALE/ENGLISH':
      return {
        lang: Languages.ES,
        messages: spanish,
      };
    case 'LOCALE/ENGLISH':
      return {
        lang: Languages.EN,
        messages: english,
      };
    case 'LOCALE/DEUTSCH':
      return {
        lang: Languages.DE,
        messages: deutsch,
      };
    case 'LOCALE/FRENCH':
      return {
        lang: Languages.FR,
        messages: french,
      };

    default:
      return state;
  }
};
