import styled from 'styled-components';
import { Card } from 'antd';

export const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 14px;
  }
  .ant-card-actions > li > span {
    min-width: 0;
  }
  .ant-card-body {
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
  }
`;

export const TemplateAutor = styled.p`
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.regular};
  display: flex;
  margin: 0;
`;

export const Response = styled(TemplateAutor)`
  padding-left: 5px;
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

export const Img = styled.img<any>`
  width: 240px;
  height: auto;
  background-repeat: no-repeat;
`;

export const SelectedWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  top: -33px;
  right: 0;
`;

export const CardTitleWrapper = styled.div`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.black};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
