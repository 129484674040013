import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { message, Icon, Popover, Tooltip } from 'antd';
import { Card } from '../../../components';
import {
  AppState,
  ICreativity,
  selectCreativeAsDefault,
  Status,
  changeCreativeStatus,
} from '../../../redux/modules';
import { CustomIcon, PopOverText } from './styles';

interface IProps {
  card: ICreativity;
  intl?: InjectedIntl;
  id: string;
  selectCreativeAsDefault?: any;
  status: Status;
  changeCreativeStatus?: any;
  isAdmin: boolean;
  openModalDelete?: (creativeId: string) => void;
  selected?: boolean | undefined;
  actions?: React.ReactNode[];
}

class CreativeCard extends React.Component<IProps> {
  getChangeStatusHandler = (id: string, status: Status) => () => {
    return this.props.changeCreativeStatus(id, status);
  };

  handleSetAsDefault(id: string) {
    this.props.selectCreativeAsDefault(id);
    return message.info(
      this.props.intl!.formatMessage({
        id: 'creativeList.defaultCreative.message',
      })
    );
  }

  renderReviewActions = (id: string) => {
    return [
      <CustomIcon
        type="close"
        className="redHover"
        onClick={this.getChangeStatusHandler(id, Status.DRAFT)}
      />,
      <CustomIcon
        type="check"
        onClick={this.getChangeStatusHandler(id, Status.PUBLISHED)}
      />,
    ];
  };

  renderCreativeActions = (
    id: string,
    status: Status,
    selected: boolean | undefined,
    isAdmin: boolean,
    openModalDelete?: any
  ) => {
    const content = (
      <div>
        <PopOverText>
          <FormattedMessage id="creativeList.deletePopOver.title" />
        </PopOverText>
        <PopOverText>
          <FormattedMessage id="creativeList.deletePopOver.text1" />
        </PopOverText>
        <PopOverText>
          <FormattedMessage id="creativeList.deletePopOver.text2" />
        </PopOverText>
      </div>
    );
    return [
      <Tooltip
        placement="top"
        title={this.props.intl!.formatMessage({
          id: 'creativeList.edit.tooltip.title',
        })}
      >
        <Link to={`/editor/${id}`} key={id}>
          <Icon type="edit" />
        </Link>
      </Tooltip>,

      isAdmin ? (
        selected ? (
          <Popover
            content={this.props.intl!.formatMessage({
              id: 'creativeList.alreadyDefault.popOver',
            })}
            trigger="hover"
            placement="top"
          >
            <CustomIcon type="pushpin" className={'disabled'} />
          </Popover>
        ) : (
          <Tooltip
            placement="top"
            title={this.props.intl!.formatMessage({
              id: 'creativeList.setDefault.tooltip.title',
            })}
          >
            <Icon type="pushpin" onClick={() => this.handleSetAsDefault(id)} />
          </Tooltip>
        )
      ) : (
        <Popover
          content={this.props.intl!.formatMessage({
            id: 'creativeList.notAdmin.popOver',
          })}
          trigger="hover"
          placement="top"
        >
          <CustomIcon type="pushpin" className="disabled" />
        </Popover>
      ),

      status === Status.PUBLISHED || selected ? (
        <Popover content={content} trigger="hover" placement="top">
          <CustomIcon className="disabled" type="delete" />
        </Popover>
      ) : (
        <Tooltip
          placement="top"
          title={this.props.intl!.formatMessage({
            id: 'creativeList.delete.tooltip.title',
          })}
        >
          <CustomIcon
            className="redHover"
            type="delete"
            onClick={() => openModalDelete(id)}
          />
        </Tooltip>
      ),
    ];
  };

  render() {
    const { status, selected, id, isAdmin, card, openModalDelete } = this.props;
    return (
      <Card
        {...card}
        actions={
          isAdmin && status === Status.PENDING_VALIDATION
            ? this.renderReviewActions(id)
            : this.renderCreativeActions(
                id,
                status,
                selected,
                isAdmin,
                openModalDelete
              )
        }
        status={status}
      />
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => {},
  {
    selectCreativeAsDefault,
    changeCreativeStatus,
  }
)(injectIntl<any>(CreativeCard));
