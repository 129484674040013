import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntl } from 'react-intl';
import {
  selectCurrentCreativities,
  selectNextCreativities,
} from '../../redux/modules/creativities/selector';
import { Bar, NoCards, Card } from '../../components';
import { ContainerLayout, ContainerSection, Container } from './styles';
import FilterBox from './FilterBox';
import { AppState } from '../../redux/modules';
import { ICreativity } from '../../redux/modules/creativities';

interface IProps {
  current: ICreativity[];
  next: ICreativity[];
  intl: InjectedIntl;
}

class Dashboard extends React.Component<IProps> {
  render() {
    const { current, next } = this.props;
    return (
      <ContainerLayout className="dashboard">
        <ContainerSection>
          <FilterBox />
          <Bar
            label={this.props.intl!.formatMessage({ id: 'dashboard.current' })}
          />
          {!current.length ? (
            <NoCards />
          ) : (
            <Container>
              {current.map(card => (
                <Card {...card} time />
              ))}
            </Container>
          )}
          <Bar
            label={this.props.intl!.formatMessage({ id: 'dashboard.next' })}
          />
          {!next.length ? (
            <NoCards />
          ) : (
            <Container>
              {next.map(card => (
                <Card {...card} time />
              ))}
            </Container>
          )}
        </ContainerSection>
      </ContainerLayout>
    );
  }
}

export default connect((state: AppState) => ({
  current: selectCurrentCreativities(state),
  next: selectNextCreativities(state),
  filters: state.filters,
}))(injectIntl<any>(Dashboard));
