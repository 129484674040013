export type SetFilterAction = {
  type: 'FILTER/SET_FILTER';
  value: StatusFilter | GroupFilter;
  filter: Filter;
};

export enum Filter {
  GROUP = 'group',
  STATUS = 'status',
}

export enum StatusFilter {
  NONE = '',
  ALL = 'All',
  PENDING = 'Pending Validation',
  PUBLISHED = 'Published',
  BROADCASTED = 'Broadcasted',
  DRAFT = 'Draft',
}

export type GroupFilter = 'All' | string;

export type SetFilterActionCreator = (
  filter: Filter,
  value: StatusFilter | GroupFilter
) => FilterAction;

export type FilterAction = SetFilterAction;
