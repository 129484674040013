import URI from 'urijs';
import { api, checkStatus, toJSON } from '../';
import { getThumbnailUrl, getHeaders } from '../request';

const requestUpload = async (file: any, s3 = false) => {
  const url = `${api}/uploads/request` + (s3 ? '/s3' : '');
  const headers = await getHeaders();
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify({ name: file.name, type: file.type }),
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(toJSON);
};

export const uploadFile = (file: any, s3 = false) => {
  return requestUpload(file, s3).then(async response => {
    const hs = new Headers({
      'Content-Type': file.type,
      'Content-Length': file.size,
      Authorization: `Bearer ${response.token}`,
    });

    if (!s3) {
      return fetch(response.url, { method: 'POST', headers: hs, body: file })
        .then(checkStatus)
        .then(toJSON)
        .then(json => makePublic(json))
        .then(res => res.mediaLink);
    }

    return toS3(file)(response);
  });
};

const toS3 = (file: any) => (response: any) => {
  let formData = new FormData();

  for (var param in response.params) {
    formData.append(param, response.params[param]);
  }
  formData.append('file', file);

  return fetch(response.url, { method: 'POST', body: formData })
    .then(checkStatus)
    .then(toText)
    .then(toXML)
    .then(xml => xml.getElementsByTagName('Location')[0].textContent);
};

export const toText = (response: any) => response.text();
export const toXML = (text: string) => {
  const parser = new DOMParser();

  return parser.parseFromString(text, 'application/xml');
};

export const validateDimensions = async (
  currentUrl: string,
  newUrl: string
): Promise<any> => {
  const url = `${api}/uploads/validate`;
  const headers = await getHeaders();

  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify({ currentUrl, newUrl }),
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(toJSON);
};

const makePublic = async (file: File) => {
  const url = `${api}/uploads/make/public`;
  const headers = await getHeaders();

  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(file),
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(toJSON);
};

export const regenerateThumbnail = (creativeId: string) => {
  const url = new URI(getThumbnailUrl(creativeId))
    .addSearch('regenerate', 1)
    .toString();

  return fetch(url);
};
