import React, { PureComponent } from 'react';
import { colors } from '../../../theme';
import { Tab, TabsPanel, StatusTag } from '../../../components';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Title,
  Small,
  Tabs,
  InfoContent,
  StatusStyled,
} from './styles';
import { Status } from '../../../redux/modules';
interface IProps {
  status: Status;
  onSelectSlide: any;
  title?: string;
  created: string;
  activeSlide: number;
  slides: any[];
  emergency?: any;
}

export class Info extends PureComponent<IProps> {
  onChangeTab = (activeKey: string) => {
    const { slides } = this.props;
    const selectSlide = slides.findIndex(x => x.name === activeKey);
    this.props.onSelectSlide(selectSlide);
  };

  renderSlideTabs = () => {
    const { slides } = this.props;
    return (
      slides &&
      slides.map((item, i) => (
        <Tab
          onClick={() => this.props.onSelectSlide(i)}
          tab={item.name}
          key={`${item.name}`}
        />
      ))
    );
  };

  renderPanelTabs = () => {
    const { slides, activeSlide } = this.props;
    const defaultActiveKey = (slides && slides[activeSlide].name) || 0;
    return (
      <TabsPanel
        onChange={this.onChangeTab}
        defaultActiveKey={defaultActiveKey}
      >
        {this.renderSlideTabs()}
      </TabsPanel>
    );
  };

  render() {
    const { created, title } = this.props;

    return (
      <Container>
        <Title>{title}</Title>
        <InfoContent>
          <div>
            <Small>
              <FormattedMessage id="cardInfo.detail.created" />
              {` ${created}`}
            </Small>
          </div>
          {!this.props.emergency && (
            <div>
              <StatusTag status={this.props.status} />
            </div>
          )}
        </InfoContent>
        <Tabs>{this.renderPanelTabs()}</Tabs>
      </Container>
    );
  }
}
