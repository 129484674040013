import styled from 'styled-components';
import { Upload } from 'antd';

export const TextUpload = styled.p`
  color: ${props => props.theme.colors.megaDarkGrey};
  font-size: ${props => props.theme.sizes.S};
  padding: 10px 10px 10px 20px;
  margin: 0;
  align-self: center;
`;

export const UploadItem = styled(Upload)`
  padding: 10px;
  & img {
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    &:hover {
      border: 1px solid ${props => props.theme.colors.blue};
    }
  }
`;

export const CustomDivUpload = styled.div`
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
