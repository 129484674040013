import { FilterAction, GroupFilter, StatusFilter } from './types';
import { Reducer } from 'redux';

const initialState: IFilterState = {
  filters: {},
};

interface Filters {
  status?: StatusFilter;
  group?: GroupFilter;
}

export interface IFilterState {
  filters: Filters;
}

export const filters: Reducer<IFilterState, FilterAction> = (
  state = initialState,
  action: FilterAction
) => {
  switch (action.type) {
    case 'FILTER/SET_FILTER':
      const filters: Filters = { ...state.filters };
      filters[action.filter] = action.value;
      return {
        ...state,
        filters,
      };
    default:
      return state;
  }
};
