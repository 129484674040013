import React, { PureComponent, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import {
  AppState,
  resetPassword,
  PasswordResetActionCreator,
} from '../../redux/modules';
import { Button, Input, FormBox, InstanceName } from '../../components';
import { instanceConfigs } from '../../utils';
import * as logos from '../../assets/logos';
import * as backgrounds from '../../assets/images';
import {
  Wrapper,
  Header,
  Login,
  Container,
  LoginButton,
  SubmitButton,
  MessageBox,
  ErrorMessage,
  FlexWrapper,
  ErrorFlexWrapper,
  RightContainer,
  LeftContainer,
} from './styles';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

const { REACT_APP_INSTANCE } = process.env;

const config =
  instanceConfigs[REACT_APP_INSTANCE as string] || instanceConfigs.default;
const logo = (logos as any)[config.logo];
const background = (backgrounds as any)[config.background];

interface IProps {
  resetPassword: PasswordResetActionCreator;
  message?: string;
  intl: InjectedIntl;
}

interface IState {
  username?: string;
  error?: string;
  sent?: boolean;
}

interface FormElement {
  name: keyof IState;
  value: string;
}

const isValid = (value: string) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

class PasswordReset extends PureComponent<IProps, IState> {
  state = {
    username: '',
    error: '',
    sent: false,
  };

  handleChange = (event: ChangeEvent<FormElement>) => {
    const target = event.target;

    this.setState({
      error: '',
      username: target.value,
    });
  };

  handleSubmit = () => {
    if (!isValid(this.state.username)) {
      return this.setState({
        error: 'passwordReset.error.invalidEmail',
      });
    }

    this.props.resetPassword({
      username: this.state.username,
    });
    this.setState({ sent: true });
  };

  resetSend = () => {
    this.setState({ sent: false, error: '', username: '' });
  };

  render() {
    return (
      <Wrapper>
        <LeftContainer>
          <Header>
            <InstanceName name={config.name} logo={logo} />
          </Header>
          <Container>
            {this.state.sent && this.props.message ? (
              <FormBox background={config.color}>
                <>
                  <MessageBox>
                    <FormattedMessage id={this.props.message} />
                  </MessageBox>
                  <FlexWrapper>
                    <FlexWrapper>
                      <Login
                        to={'/login'}
                        text={this.props.intl!.formatMessage({
                          id: 'route.login',
                        })}
                      />
                    </FlexWrapper>
                    <LoginButton>
                      <Button
                        type="primary"
                        button={this.props.intl!.formatMessage({
                          id: 'passwordReset.sendAgain',
                        })}
                        onClick={this.resetSend}
                      />
                    </LoginButton>
                  </FlexWrapper>
                </>
              </FormBox>
            ) : (
              <FormBox background={config.color}>
                <>
                  <Input
                    value={this.state.username}
                    name="username"
                    placeholder={'user.username'}
                    onChange={this.handleChange}
                    required={true}
                  />
                  <FlexWrapper>
                    <ErrorFlexWrapper>
                      <Login
                        to={'/login'}
                        text={this.props.intl!.formatMessage({
                          id: 'route.login',
                        })}
                      />
                      <ErrorMessage>
                        {this.state.error && (
                          <FormattedMessage id={this.state.error} />
                        )}
                      </ErrorMessage>
                    </ErrorFlexWrapper>
                    <SubmitButton>
                      <Button
                        htmlType="submit"
                        disabled={!this.state.username}
                        button={this.props.intl!.formatMessage({
                          id: 'actions.button.send',
                        })}
                        onClick={this.handleSubmit}
                      />
                    </SubmitButton>
                  </FlexWrapper>
                </>
              </FormBox>
            )}
          </Container>
        </LeftContainer>
        <RightContainer background={background} />
      </Wrapper>
    );
  }
}

export default connect(
  (state: AppState) => ({
    language: state.locale.lang,
    message: state.user.message,
  }),
  {
    resetPassword,
  }
)(injectIntl<any>(PasswordReset));
