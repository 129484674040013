import React, { PureComponent } from 'react';
import {
  BlueButton,
  GreyButton,
  RedButton,
  WhiteBlueButton,
  WhiteRedButton,
  EmergencySelect,
  ResetButton,
  ResetBlueWhiteButton,
  Basicbutton,
} from './styles';

interface IProps {
  type?: string;
  htmlType?: string;
  button?: string;
  onClick?: React.MouseEventHandler;
  padding?: number;
  disabled?: boolean;
  className?: string;
}

class Button extends PureComponent<IProps> {
  render() {
    const { type, disabled, padding, htmlType, className } = this.props;
    if (type === 'delete') {
      return (
        <Basicbutton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </Basicbutton>
      );
    }
    if (type === 'warning') {
      return (
        <WhiteRedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </WhiteRedButton>
      );
    }
    if (type === 'grey') {
      return (
        <GreyButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </GreyButton>
      );
    }
    if (type === 'red') {
      return (
        <RedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </RedButton>
      );
    }
    if (type === 'whiteBlue') {
      return (
        <WhiteBlueButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </WhiteBlueButton>
      );
    }
    if (type === 'whiteRed') {
      return (
        <WhiteRedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </WhiteRedButton>
      );
    }
    if (type === 'emergencySelect') {
      return (
        <EmergencySelect
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </EmergencySelect>
      );
    }
    if (type === 'reset') {
      return (
        <ResetButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </ResetButton>
      );
    }
    if (type === 'resetBlueWhite') {
      return (
        <ResetBlueWhiteButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType}
          className={className}
        >
          {this.props.button}
        </ResetBlueWhiteButton>
      );
    }

    return (
      <BlueButton
        disabled={disabled}
        padding={padding}
        onClick={this.props.onClick}
        htmlType={htmlType}
        className={className}
      >
        {this.props.button}
      </BlueButton>
    );
  }
}
export default Button;
