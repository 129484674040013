import React from 'react';

import { request, checkStatus, toJSON } from '../../utils/request';
import * as logos from '../../assets/logos';
import * as backgrounds from '../../assets/images';
import { Modal } from '../../components';

interface IProps {}
interface IState {
  name: string;
  background: string;
  logo: string;
  color: string;
  language: string;
}

const defaultValues: IState = {
  name: '',
  background: '',
  logo: '',
  color: '',
  language: '',
};

const ConfigContext = React.createContext(defaultValues);
const { REACT_APP_INSTANCE } = process.env;

export default class ConfigProvider extends React.Component<IProps, IState> {
  state: IState = {
    name: '',
    background: '',
    logo: '',
    color: '',
    language: '',
  };

  async componentDidMount() {
    request(`instances-config/instanceId/${REACT_APP_INSTANCE}`)
      .then(checkStatus)
      .then(toJSON)
      .then(instance => {
        return this.setState({
          ...instance[0],
        });
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }

  render() {
    const logo = (logos as any)[this.state.logo];
    const background = (backgrounds as any)[this.state.background];
    return (
      <ConfigContext.Provider
        value={{
          ...this.state,
          ...(logo && { logo }),
          ...(background && { background }),
        }}
      >
        {this.props.children}
      </ConfigContext.Provider>
    );
  }
}

export const ConfigConsumer = ConfigContext.Consumer;
