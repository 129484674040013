import React, { PureComponent } from 'react';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import { CustomCheckboxGroup } from './styles';

class CheckboxGroup extends PureComponent<CheckboxGroupProps> {
  render() {
    const {
      onChange,
      disabled,
      value,
      className,
      options,
      defaultValue,
    } = this.props;
    return (
      <CustomCheckboxGroup
        onChange={onChange}
        disabled={disabled}
        value={value}
        className={className}
        options={options}
        defaultValue={defaultValue}
      />
    );
  }
}

export default CheckboxGroup;
