import React, { Component } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { withAuth } from '@okta/okta-react';
import { Box, Input } from '../../../components';
import { ELEMENTS } from '../../../utils';
import {
  IPlayer,
  IVisibility,
  ISlide,
  IElement,
  Status,
} from '../../../redux/modules';

import { Container, Section, ElementsSection, H1Custom } from './styles';

import Actions from './Actions';
import LocationSelector from './LocationSelector';
import ScheduleSelector from './ScheduleSelector';
import { ImageElement, VideoElement, QrElement, TextElement } from './Elements';

interface IProps {
  creativityId: string;
  updateElementProp?: any;
  updateCreativity: any;
  updateTitle: any;
  updatePlayers: any;
  changeSelection: any;
  visibility?: IVisibility;
  creativityTitle: any;
  intl: InjectedIntl;
  slide: ISlide;
  emergency: any;
  status: Status;
  playersCreativity: IPlayer[];
  players: IPlayer[];
  hasPublishedEmergency: boolean;
  hasAllPlayersSelected: boolean;
  isPublished?: boolean;
  deleteCreativity: any;
  selected?: boolean | undefined;
  auth?: any;
}

interface IState {
  disabledImage: boolean;
  active?: boolean;
  value?: string;
  error?: boolean;
}

class Menu extends Component<IProps, IState> {
  state: IState = {
    disabledImage: false,
  };

  renderElement = (element: IElement, index: number) => {
    const { creativityId, status } = this.props;

    if (element.locked) {
      return null;
    }

    switch (element.type) {
      case ELEMENTS.TEXT:
        return (
          <TextElement
            key={index}
            index={index}
            creativeId={creativityId}
            element={element}
            status={status}
          />
        );
      case ELEMENTS.IMAGE:
        return (
          <ImageElement
            key={index}
            creativeId={creativityId}
            element={element}
            index={index}
            status={status}
          />
        );
      case ELEMENTS.QR:
        return (
          <QrElement
            key={index}
            index={index}
            creativeId={creativityId}
            element={element}
            status={status}
          />
        );
      case ELEMENTS.VIDEO:
        return (
          <VideoElement
            key={index}
            creativeId={creativityId}
            element={element}
            index={index}
            status={status}
          />
        );
      case ELEMENTS.WEATHER:
        return null;
      default:
        return null;
    }
  };

  render() {
    const {
      creativityId,
      creativityTitle,
      intl,
      slide,
      emergency,
      status,
      selected,
    } = this.props;

    return (
      <Container>
        <Box
          defaultTitleWrapper={intl.formatMessage({
            id: emergency ? 'editor.info.emergency.title' : 'editor.info.title',
          })}
        >
          <form>
            {!emergency && (
              <Section>
                <H1Custom>
                  <FormattedMessage id={'editor.info.field.creativity'} />
                </H1Custom>
                <Input
                  value={creativityTitle || ''}
                  placeholder={'editor.info.placeholer.creativity'}
                  onChange={event =>
                    this.props.updateTitle(
                      this.props.creativityId,
                      'creativity',
                      event.target.value
                    )
                  }
                />
              </Section>
            )}
            <ElementsSection>
              {slide &&
                slide.elements.map((item, i) => this.renderElement(item, i))}
            </ElementsSection>
            <LocationSelector id={creativityId} status={status} />
            {!emergency && (
              <ScheduleSelector
                visibility={this.props.visibility}
                creativityId={creativityId}
                status={status}
              />
            )}
            <Actions
              id={creativityId}
              isEmergency={emergency}
              status={status}
              selected={selected}
            />
          </form>
        </Box>
      </Container>
    );
  }
}

export default withAuth(injectIntl(Menu));
