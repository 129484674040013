import {
  SetLanguageAction,
  Languages,
  SetLanguageActionCreator,
} from './types';

export const setSpanish = (): SetLanguageAction => ({
  type: 'LOCALE/SPANISH',
});
export const setEnglish = (): SetLanguageAction => ({
  type: 'LOCALE/ENGLISH',
});
export const setDeutsch = (): SetLanguageAction => ({
  type: 'LOCALE/DEUTSCH',
});
export const setFrench = (): SetLanguageAction => ({
  type: 'LOCALE/FRENCH',
});

export const loadLanguage: SetLanguageActionCreator = (lang: Languages) => {
  switch (lang) {
    case Languages.ES:
      return setSpanish();
    case Languages.EN:
      return setEnglish();
    case Languages.DE:
      return setDeutsch();
    case Languages.FR:
      return setFrench();
    default:
      return setFrench();
  }
};
