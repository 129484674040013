import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { AppState } from '../../../redux/modules';
import {
  setFilter,
  Filter,
  StatusFilter,
  statusFilterList,
  FilterAction,
} from '../../../redux/modules/filters';
import { Box, Button, Select, Text } from '../../../components';

import { Body, Container } from './styles';

interface IProps {
  setFilter: (filter: Filter, value: StatusFilter) => FilterAction;
  currentStatus?: StatusFilter;
  statusList?: any[];
  intl?: InjectedIntl;
}

class FilterBox extends React.PureComponent<IProps> {
  filterHandler = (value: StatusFilter) => {
    this.props.setFilter(Filter.STATUS, value);
  };

  onResetFilterStatus = () => {
    this.props.setFilter(Filter.STATUS, StatusFilter.ALL);
  };

  render() {
    return (
      <Box
        defaultTitleWrapper={this.props.intl!.formatMessage({
          id: 'creativeList.filterCreative',
        })}
      >
        <Body>
          <Text bold={true}>
            <FormattedMessage id="creativeList.byStatus" />
          </Text>
          <Container>
            {this.props.statusList && (
              <Select
                items={this.props.statusList}
                defaultValue={this.props.intl!.formatMessage({
                  id: 'app.all',
                })}
                onPress={this.filterHandler}
                placeholder={this.props.intl!.formatMessage({
                  id: 'app.all',
                })}
                value={this.props.currentStatus}
              />
            )}
            <Button
              type="resetBlueWhite"
              onClick={this.onResetFilterStatus}
              button={this.props.intl!.formatMessage({
                id: 'actions.buttons.reset',
              })}
            />
          </Container>
        </Body>
      </Box>
    );
  }
}

export default connect(
  (state: AppState) => ({
    statusList: statusFilterList,
    currentStatus: state.filters.filters[Filter.STATUS],
  }),
  {
    setFilter,
  }
)(injectIntl<any>(FilterBox));
