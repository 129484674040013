import styled from 'styled-components';

export const Tag = styled.div`
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  font-size: ${props => props.theme.sizes.S};
  &.draft-tag {
    border: 1px solid #ffbb96;
    background-color: #fff2e8;
    color: #ffbb96;
  }
  &.waiting-tag {
    border: 1px solid #ffadd2;
    background-color: rgb(255, 236, 244);
    color: #ffadd2;
  }
  &.publish-tag {
    border: 1px solid #52c41a;
    background-color: rgb(244, 255, 233);
    color: #52c41a;
  }
  &.created-tag {
    border: 1px solid #1890ff;
    background-color: rgb(224, 246, 255);
    color: #1890ff;
  }
`;
export const TextTag = styled.p`
  padding: 5px;
  margin: 0;
`;
