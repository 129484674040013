import React, { PureComponent } from 'react';
import moment from 'moment';
import { InjectedIntl, injectIntl } from 'react-intl';
import { CustomPicker, Date, Time } from './styles';

interface IProps {
  type?: string;
  onPress?: (date: moment.Moment, dateString: string) => void;
  value?: moment.Moment;
  id?: string;
  disabled?: boolean;
  disabledDate?: (date: moment.Moment) => boolean;
  intl?: InjectedIntl;
  placeholder?: string;
}

export class PickerComponent extends PureComponent<IProps> {
  public render() {
    const { type, onPress, value, id, disabled, disabledDate } = this.props;

    if (type === 'date') {
      return (
        <CustomPicker>
          <Date
            key={id}
            disabled={disabled}
            disabledDate={disabledDate}
            defaultValue={value}
            onChange={onPress}
            allowClear={true}
            placeholder={this.props.intl!.formatMessage({ id: 'ddmmyy' })}
            format={'DD/MM/YY'}
          />
        </CustomPicker>
      );
    }
    return (
      <CustomPicker>
        <Time
          key={id}
          disabled={disabled}
          allowClear={true}
          defaultValue={value}
          onChange={onPress}
          placeholder={'HH:MM'}
          format={'HH:mm'}
          minuteStep={15}
        />
      </CustomPicker>
    );
  }
}

export const Picker = injectIntl<any>(PickerComponent);
