import React, { PureComponent } from 'react';
import { IFormat, ISlide } from '../../../redux/modules';
import Slide from './Slide';

interface IProps {
  activeElement: number;
  format: IFormat;
  slide: ISlide;
}

export class Canvas extends PureComponent<IProps> {
  render() {
    return (
      <Slide
        activeElement={this.props.activeElement}
        format={this.props.format}
        slide={this.props.slide}
        zoom={0.5}
      />
    );
  }
}
