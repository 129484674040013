import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { TagButton, Modal, Button } from '../../components';
import { message } from 'antd';
import {
  ContainerLayout,
  ContainerSection,
  ContainerSectionCol,
  ContainerSectionRow,
  TagButtonWrapper,
  TagText,
  TagBoldText,
  Container,
  WrapperContainer,
  WrapperButton,
  WrapperText,
} from './styles';
import {
  AppState,
  ICreativity,
  Filter,
  selectFilteredCreativities,
  deleteCreativity,
  selectCreativeAsDefault,
  Status,
  changeCreativeStatus,
} from '../../redux/modules';
import {
  StatusFilter,
  FilterAction,
  setFilter,
} from '../../redux/modules/filters';
import StatusFilters from './StatusFilter';
import TemplateList from './TemplateList';
import CreativeCard from './CreativeCard';
import { UserConsumer } from '../../providers';

interface IProps {
  creativities: ICreativity[];
  currentStatus?: StatusFilter;
  setFilter: (filter: Filter, value: StatusFilter) => FilterAction;
  intl: InjectedIntl;
  deleteCreativity?: any;
  id: string;
  error?: any;
  selectCreativeAsDefault?: any;
  status: Status;
  changeCreativeStatus?: any;
  actions?: React.ReactNode[] | undefined;
  openModalDelete?: (creativeId: string) => void;
  selected?: boolean | undefined;
}

interface IState {
  visibleDeleteModal: boolean;
  creativeToDelete: string;
}

class Creative extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      visibleDeleteModal: false,
      creativeToDelete: '',
    };

    this.getFilterCopy = this.getFilterCopy.bind(this);
    this.handleCloseButton = this.handleCloseButton.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.handleDoneDeleteModal = this.handleDoneDeleteModal.bind(this);
    this.handleCancelDeleteModal = this.handleCancelDeleteModal.bind(this);
  }

  getFilterCopy = () => {
    switch (this.props.currentStatus) {
      case 'Broadcasted': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.broadcasted',
        });
      }
      case 'Published': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.published',
        });
      }
      case 'Draft': {
        return this.props.intl.formatMessage({ id: 'creativeList.tag.drafts' });
      }
      default:
        return this.props.intl.formatMessage({ id: 'creativeList.tag.all' });
    }
  };

  handleCloseButton = () => {
    this.props.setFilter(Filter.STATUS, StatusFilter.ALL);
  };

  openModalDelete(creativeId: string) {
    this.setState({ visibleDeleteModal: true, creativeToDelete: creativeId });
  }

  closeModalDelete() {
    this.setState({ visibleDeleteModal: false });
  }

  handleDoneDeleteModal() {
    this.props.deleteCreativity(this.state.creativeToDelete);
    this.closeModalDelete();
    return message.info(
      this.props.intl.formatMessage({
        id: 'creativeList.deleteCreative.message',
      })
    );
  }

  handleCancelDeleteModal() {
    this.closeModalDelete();
  }

  render() {
    const { creativities, intl, actions } = this.props;
    const { visibleDeleteModal } = this.state;
    return (
      <UserConsumer>
        {user => (
          <Fragment>
            <Modal
              title={intl!.formatMessage({
                id: 'actions.modalDeleteTitle',
              })}
              handleCustomOk={this.handleDoneDeleteModal}
              handleCustomCancel={this.handleCancelDeleteModal}
              visible={visibleDeleteModal}
              noFooter={true}
            >
              <WrapperContainer>
                <WrapperText>
                  <FormattedMessage id="actions.modalDeleteText" />
                </WrapperText>
                <WrapperButton>
                  <Button
                    type="whiteBlue"
                    button={intl!.formatMessage({
                      id: 'actions.modalCancel',
                    })}
                    onClick={this.handleCancelDeleteModal}
                  />
                  <Button
                    button={intl!.formatMessage({
                      id: 'actions.deleteModalConfirm',
                    })}
                    onClick={this.handleDoneDeleteModal}
                  />
                </WrapperButton>
              </WrapperContainer>
            </Modal>

            <ContainerLayout className="dashboard">
              <ContainerSection>
                <ContainerSectionRow gutter={16}>
                  <ContainerSectionCol span={12}>
                    <StatusFilters />
                  </ContainerSectionCol>
                  <ContainerSectionCol span={12}>
                    <TemplateList />
                  </ContainerSectionCol>
                </ContainerSectionRow>
                <TagButtonWrapper>
                  {this.props.currentStatus &&
                    this.props.currentStatus !== StatusFilter.ALL && (
                      <TagButton onClick={this.handleCloseButton}>
                        <TagText>
                          <FormattedMessage id={'creativeList.tag.showing'} />
                        </TagText>
                        <TagBoldText>{this.getFilterCopy()}</TagBoldText>
                      </TagButton>
                    )}
                </TagButtonWrapper>
                <Container>
                  {(creativities || []).map(card => (
                    <CreativeCard
                      card={card}
                      actions={actions}
                      status={card.status}
                      isAdmin={user.isAdmin}
                      openModalDelete={this.openModalDelete}
                      id={card._id}
                      selected={card.selected}
                    />
                  ))}
                </Container>
              </ContainerSection>
            </ContainerLayout>
          </Fragment>
        )}
      </UserConsumer>
    );
  }
}

export default connect(
  (state: AppState) => {
    const getCreativities = () => {
      const creatives = selectFilteredCreativities(Filter.STATUS)(state);
      return creatives;
    };
    return {
      creativities: getCreativities(),
      currentStatus: state.filters.filters[Filter.STATUS],
    };
  },
  {
    setFilter,
    deleteCreativity,
    selectCreativeAsDefault,
    changeCreativeStatus,
  }
)(injectIntl<any>(Creative));
