import React, { Component, ReactElement, Fragment } from 'react';
import { SelectValue } from 'antd/lib/select';
import Icon from 'antd/lib/icon';
import { Divider } from 'antd';
import { CustomSelect, Option, Label } from './styles';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import ListItemSelect from '../ListItemSelect';

interface IProps {
  defaultValue?: string;
  defaultActiveFirstOption?: boolean;
  placeholder?: string;
  onPress?: (value: SelectValue, option?: ReactElement<any>) => void;
  onFocus?: () => void;
  value?: string;
  items: IItemSelect[];
  label?: string;
  disabled?: boolean;
  width?: string;
  intl?: InjectedIntl;
  favouriteItems?: IItemFavourite[];
}

export interface IItemSelect {
  value?: string;
  label?: string;
  key?: string;
}

export interface IItemFavourite extends IItemSelect {
  favourite?: boolean;
}

interface ICustomProps {
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: SelectValue, option?: ReactElement<any>) => void;
  onFocus?: (value: SelectValue, option?: ReactElement<any>) => void;
  value?: string;
  width?: string;
  defaultActiveFirstOption?: boolean;
}

export class Select extends Component<IProps> {
  render() {
    const {
      defaultValue,
      placeholder,
      onPress,
      value,
      label,
      onFocus,
      width,
      defaultActiveFirstOption,
    } = this.props;

    const customProps: ICustomProps = {
      defaultValue,
      placeholder,
      onChange: onPress,
      onFocus,
      width,
      defaultActiveFirstOption,
    };
    if (value) {
      customProps.value = value;
    }

    const favouritesListLength =
      this.props.favouriteItems && this.props.favouriteItems.length;

    return (
      <Fragment>
        {!!label && <Label>{label}</Label>}
        {this.props.favouriteItems ? (
          <CustomSelect {...customProps}>
            {this.props.favouriteItems &&
              this.props.favouriteItems.map(item => (
                <Option
                  key={item.key}
                  value={item.value}
                  style={{ padding: '12px 12px 0 12px' }}
                >
                  <>
                    <Icon type="star" style={{ color: '#1890FF' }} />
                    {'  '}
                    <FormattedMessage id={item.label || ''} />
                  </>
                  {favouritesListLength ===
                  (this.props.favouriteItems &&
                    this.props.favouriteItems.indexOf(item) + 1) ? (
                    <Divider style={{ margin: '14px 0 0 0' }} />
                  ) : (
                    ''
                  )}
                </Option>
              ))}
            {this.props.items.map(item => (
              <Option
                key={item.key}
                value={item.value}
                style={{ padding: '12px' }}
              >
                <FormattedMessage id={item.label || ''} />
              </Option>
            ))}
          </CustomSelect>
        ) : (
          <CustomSelect {...customProps}>
            {this.props.items.map(item => (
              <Option key={item.key} value={item.value}>
                <FormattedMessage id={item.label || ''} />
              </Option>
            ))}
          </CustomSelect>
        )}
      </Fragment>
    );
  }
}
injectIntl<any>(Select);
