import React, { Component, Fragment, createRef } from 'react';
import { uid } from 'react-uid';
import { Container } from './styles';
import Element from './Element';
import { IFormat, ISlide } from '../../../../redux/modules';
import { TogglePreview } from './Element/elements/styles';
import { Icon } from '../../../../components/index';
import { ELEMENTS } from '../../../../utils';

interface IProps {
  format: IFormat;
  zoom: number;
  slide: ISlide;
  activeElement: number;
}

interface IState {
  playing: boolean;
}

export default class Slide extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      playing: false,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.slide !== prevProps.slide &&
      this.props.slide.elements[0].type === 'video'
    ) {
      this.setState({ playing: false });
    }
  }

  getStyle() {
    const { format, zoom } = this.props;

    return {
      cursor: 'default',
      height: ((format && format.height) || 0) * zoom,
      width: ((format && format.width) || 0) * zoom,
    };
  }

  togglePlay = (): void => {
    this.setState({ playing: !this.state.playing });
  };

  slide: React.RefObject<any> = createRef();

  render() {
    const { slide, zoom, activeElement } = this.props;
    const { playing } = this.state;

    return (
      <Container ref={this.slide} style={this.getStyle()}>
        {slide &&
          slide.elements.map((element, i) => {
            return (
              <Fragment key={uid(element)}>
                <Element
                  element={element}
                  index={i}
                  activeElement={activeElement}
                  zoom={zoom}
                  playing={playing}
                />
                {element.type === ELEMENTS.VIDEO && (
                  <TogglePreview onClick={() => this.togglePlay()}>
                    <Icon name={playing ? 'pause' : 'play'} />
                  </TogglePreview>
                )}
              </Fragment>
            );
          })}
      </Container>
    );
  }
}
