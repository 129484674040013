import { createSelector } from 'reselect';
import { AppState } from '../';
import { IGroup, IPlayer } from './types';
import { selectCreativitiesById, ICreativity } from '../creativities';

export const selectPlayerGroups = (state: AppState): IGroup[] => {
  return state.players.all.reduce((groups, player) => {
    if (!player.groups) {
      return groups;
    }

    return player.groups.reduce(
      (groups, playerGroup) => addToGroups(player, playerGroup, groups),
      groups
    );
  }, []);
};

const addToGroups = (
  player: IPlayer,
  playerGroup: string,
  groups: IGroup[]
) => {
  const group = groups.find(group => group.name === playerGroup);

  return group
    ? [
        ...groups.filter(group => group.name !== playerGroup),
        { ...group, players: [...group.players, player] },
      ]
    : [...groups, { _id: playerGroup, name: playerGroup, players: [player] }];
};

export const selectPlayerGroupsOptions = (state: AppState) => {
  const playerGroups = selectPlayerGroups(state);

  const initialValue = {
    label: 'All groups',
    key: 'All',
    value: 'All',
  };

  return [
    initialValue,
    ...playerGroups.map(playerGroup => ({
      label: playerGroup.name,
      key: playerGroup.name,
      value: playerGroup.name,
    })),
  ];
};

const playersAsItemSelector = (players: IPlayer[]) =>
  players.map(player => ({
    id: player._id,
    title: player.name,
    subtitle: player.address,
  }));

export const selectPlayersAsItem = (state: AppState) =>
  playersAsItemSelector(state.players.all);

export const selectCreativityPlayersAsItem = (creativityId: string) =>
  createSelector(
    (state: AppState) => selectCreativitiesById(state, creativityId),
    (creativity: ICreativity) => {
      return (
        (creativity &&
          creativity.players && [
            ...creativity.players.map(player => player._id || player),
          ]) ||
        []
      );
    }
  );

export const selectGroupsAsItem = (state: AppState) =>
  selectPlayerGroups(state).map(group => ({
    id: group._id,
    title: group.name,
  }));

export const selectCreativityGroupsAsItem = (creativityId: string) =>
  createSelector(
    (state: AppState) => selectCreativitiesById(state, creativityId),
    (creativity: ICreativity) => (creativity && creativity.groups) || []
  );
