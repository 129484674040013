import styled from 'styled-components';
import { TogglePreview } from './Element/elements/styles';

export const Container = styled.div`
  background-color: white;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  &:hover ${TogglePreview} {
    display: block;
  }
`;
