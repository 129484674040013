import React from 'react';
import { Row, Col, notification } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  IElement,
  AppState,
  changeSelection,
  updateElementProp,
  updateCreativity,
  Status,
} from '../../../../../redux/modules';
import { Input, Upload } from '../../../../../components';
import { uploadFile, validateDimensions } from '../../../../../utils';

interface IProps {
  creativeId: string;
  index: number;
  element: IElement;
  updateElementProp?: any;
  updateCreativity?: any;
  changeSelection?: any;
  intl?: InjectedIntl;
  status: Status;
}

interface IState {
  isPosting?: boolean;
}

class VideoElementClass extends React.Component<IProps, IState> {
  state: IState = {};

  handleChangeUpload = async (event: UploadChangeParam) => {
    const { element, intl } = this.props;

    if (this.state.isPosting) {
      return;
    }

    if (event.file) {
      this.setState({ isPosting: true });
    }

    try {
      const newUrl = await uploadFile(event.file.originFileObj, true);
      const { error, data } = await validateDimensions(element.url!, newUrl);

      if (error) {
        this.setState({ isPosting: false });
        return this.handleError(
          intl!.formatMessage(
            {
              id: `editor.error.uploadVideo.${error}`,
            },
            { ...data }
          )
        );
      }

      this.props.updateElementProp(this.props.creativeId, 'url', data.url);
      this.props.updateElementProp(this.props.creativeId, 'sources', false);
      this.props.updateCreativity(this.props.creativeId);

      this.handleSuccess(
        this.props.intl!.formatMessage({
          id: 'editor.uploadVideo.success',
        })
      );
    } catch (error) {
      this.handleError(
        this.props.intl!.formatMessage({
          id: 'editor.error.uploadVideo.default',
        })
      );
    }

    this.setState({ isPosting: false });
  };

  handleEnter = (index: number) => {
    this.props.changeSelection('element', index);
  };

  handleSuccess = (message: string) =>
    notification.open({
      message: this.props.intl!.formatMessage({ id: 'success' }),
      className: 'success',
      description: message,
      duration: 45,
    });

  handleError = (error: string) =>
    notification.open({
      message: this.props.intl!.formatMessage({ id: 'error' }),
      className: 'error',
      description: error,
      duration: 45,
    });

  render() {
    const {
      updateElementProp,
      creativeId,
      status,
      element,
      index,
    } = this.props;
    return (
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        onClick={() => this.handleEnter(index)}
        key={`row_img_${index}`}
      >
        <Col span={12}>
          <Input
            value={element.url || ''}
            label={element.name}
            placeholder={'editor.info.placeholer.element.image'}
            onChange={event => {
              updateElementProp(creativeId, 'url', event.target.value);
              updateElementProp(creativeId, 'sources', false);
            }}
            disabled={
              element.locked ||
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
          />
        </Col>
        <Col span={12}>
          <Upload
            isPosting={this.state.isPosting}
            disabled={
              element.locked ||
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
            showUploadList={false}
            onChange={this.handleChangeUpload}
          />
        </Col>
      </Row>
    );
  }
}

export const VideoElement = connect(
  (state: AppState, props: IProps) => ({}),
  {
    changeSelection,
    updateElementProp,
    updateCreativity,
  }
)(injectIntl(VideoElementClass as any));
