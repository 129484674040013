import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { defaultThumbnail } from '../../assets';
import { getThumbnailUrl } from '../../utils';
import { ICreativity, Status } from '../../redux/modules/creativities';
import { Card, Icon } from 'antd';
import { StatusTag } from '../../components';

import {
  Img,
  Response,
  TemplateAutor,
  SelectedWrapper,
  CardTitleWrapper,
  CustomCard,
  Wrapper,
} from './styles';

interface IProps extends ICreativity {
  selected?: boolean;
  actions?: React.ReactNode[];
  time?: boolean;
  status: Status;
}

class CardStyled extends PureComponent<IProps> {
  public render() {
    const { Meta } = Card;
    const { _id, name, actions, selected, time } = this.props;
    let start = '--';
    let end = '--';
    if (this.props.visibility !== undefined) {
      const rules =
        (this.props.visibility && this.props.visibility.rules) || [];
      const startRule = rules.find(
        x => x.type === 'date' && x.comparator === 'gte'
      );
      const endRule = rules.find(
        x => x.type === 'date' && x.comparator === 'lte'
      );
      start = startRule ? startRule.value : '--';
      end = endRule ? endRule.value : '--';
    }

    return (
      <CustomCard
        key={`cards_fake_${_id}`}
        hoverable
        cover={
          <Link to={`/editor/${_id}`} key={_id}>
            <Img src={_id ? getThumbnailUrl(_id) : defaultThumbnail} />
          </Link>
        }
        actions={actions}
      >
        <Meta
          style={{ position: 'relative' }}
          title={<CardTitleWrapper>{name}</CardTitleWrapper>}
          description={
            <div>
              {selected ? (
                <SelectedWrapper>
                  <Icon
                    type="pushpin"
                    rotate={-45}
                    theme="filled"
                    style={{ margin: '15px', color: '#0099D8' }}
                  />
                </SelectedWrapper>
              ) : (
                ''
              )}
              {time ? (
                <>
                  <TemplateAutor>
                    <FormattedMessage id="cardInfo.detail.start" />
                    <Response>{start}</Response>
                  </TemplateAutor>
                  <TemplateAutor>
                    <FormattedMessage id="cardInfo.detail.end" />
                    <Response>{end}</Response>
                  </TemplateAutor>
                </>
              ) : (
                <Wrapper>
                  <StatusTag status={this.props.status} />
                </Wrapper>
              )}
            </div>
          }
        />
      </CustomCard>
    );
  }
}
export default CardStyled;
