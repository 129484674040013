// Import libraries
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { ImplicitCallback } from '@okta/okta-react';
import { ThemeProvider } from 'styled-components';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import IntlProvider from './intl';
import Routes from './Routes';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '@okta/okta-signin-widget/dist/css/okta-theme.css';

import * as theme from '../../theme';
import { configureStore } from '../../redux/store';
import { LoadingMessage } from '../../components/index';

const ConfigProvider = React.lazy(() => import('../../providers/Config'));
const UserProvider = React.lazy(() => import('../../providers/User'));

const { REACT_APP_TITLE } = process.env;

addLocaleData([...en, ...es, ...de, ...fr]);

const { Component } = React;
const { history, store } = configureStore();

export class Root extends Component {
  componentDidMount() {
    if (REACT_APP_TITLE) {
      document.title = REACT_APP_TITLE;
    }
  }

  render() {
    return (
      <Provider store={store}>
        <IntlProvider>
          <ThemeProvider theme={theme}>
            <Suspense
              fallback={
                <LoadingMessage>
                  <FormattedMessage id="loading.message" />
                </LoadingMessage>
              }
            >
              <ConfigProvider>
                <UserProvider>
                  <ConnectedRouter history={history}>
                    <Routes />
                  </ConnectedRouter>
                </UserProvider>
              </ConfigProvider>
            </Suspense>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    );
  }
}
