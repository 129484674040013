import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0px;
  min-height: 85px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  div,
  button {
    margin: 0 5px;
  }
`;
