import styled from 'styled-components';
import { Checkbox, Col } from 'antd';
import { colors } from '../../../theme';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
import { Picker } from '../../../components';

export const Container = styled.div`
  flex: 1;
`;

export const Section = styled.div`
  margin-bottom: 20px;
`;

export const ElementsSection = styled.div`
  margin-bottom: 20px;
  padding-right: 10px;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  max-height: 425px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const SectionLine = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

export const RightSection = styled.div`
  float: right;
  font-size: 1em;
  margin-left: 35px;
  button {
    font-size: 12px;
    padding: 0 10px;
  }
`;

export const RightSectionLink = styled(RightSection)`
  color: ${colors.lightBlue};
`;

export const H1Custom = styled.h1`
  font-size: 1.3em;
  float: left;
  margin-right: 10px;
  color: #7c8c8f;
`;

export const H4Custom = styled.h4`
  font-size: 0.9em;
  color: grey;
`;
export const CheckboxCustom = styled(Checkbox)`
  font-family: 'Montserrat';
  margin: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.blue};
  }
`;

export const PickerComponent = styled(Picker)`
  margin: 100px;
`;

export const CustomButtonLarge = styled(Button)`
  padding-left: 50px;
  padding-right: 50px;
`;

export const CustomLinkMargin = styled(Link)`
  margin-left: 10px;
`;

export const CustomContainerButton = styled.div``;

export const SectionPicker = styled.div``;

export const SectionControls = styled.div``;

export const ColFix = styled(<any>Col)`
  margin-top: 17px;
`;
