import styled from 'styled-components';
import { DatePicker, TimePicker } from 'antd';

export const CustomPicker = styled.div``;

const isDisabled = (props: { disabled?: boolean }) => {
  if (props.disabled) {
    return `
      background: #fafafa;
      cursor: not-allowed;
    `;
  }

  return `cursor: pointer`;
};

export const Date = styled(DatePicker)`
  margin-right: 20px;
  .ant-input {
    color: ${props => props.theme.colors.superDarkGrey};
    height: 40px;
    ${isDisabled};
  }
`;

export const Time = styled(TimePicker)`
  width: 90%;
  margin-right: 20px;
  .ant-time-picker-input {
    height: 40px;
    color: ${props => props.theme.colors.superDarkGrey};
    ${isDisabled};
  }
`;
