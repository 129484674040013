import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

export const CustomCheckboxGroup = styled(Checkbox.Group)<CheckboxGroupProps>`
  font-family: 'Montserrat';
  margin: 10px;
  padding-bottom: 20px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.blue};
  }
`;
