import styled from 'styled-components';

export const Text = styled.div`
  background-color: transparent;
  border: none;
  display: flex;
  resize: none;
  line-height: normal;
  padding: 0;
  user-select: none;
  word-break: normal;
`;

export const Img = styled.img`
  display: block;
  -webkit-user-drag: none;
`;

export const Video = styled.div`
  display: block;
  -webkit-user-drag: none;
  position: relative;
`;

export const TogglePreview = styled.div`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 40;
  display: none;
`;
